import React from "react"

import Layout from "../components/layout"

export default () => (

<Layout>      <div id="content">
        <h1>Lessons</h1>
        <p>
          Auch pedagogisch hat Frau Servatius viel Erfahrung.  Sie hat Schüler aller Altersstufen, von 4 - 84 Jahren.  Eine besondere Ausbildung bekam sie in Massachusetts als Musiklehrerin einer Klasse von 30 benachteilgten Kindern.
        </p><p>Privatstunden gibt sie zu jeder Zeit gerne und eine Beratung ist immer kostenlos.
        </p><p>Hier spielt Frau Servatius mit einer 12-Jährigen Schülerin:
        </p><ul>
          <li><a href="music/telemann - canonic sonota 1.mp3"><i>I. Allegro</i></a></li>
          <li><a href="music/telemann - canonic sonota 2.mp3"><i>II. Andante</i></a></li>
          <li><a href="music/telemann - canonic sonota 3.mp3"><i>III. Allegro</i></a></li>
        </ul>
        <p />
      </div>
</Layout>)
